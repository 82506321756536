import React, {useState, useEffect} from "react";
import {GermanDatePicker as DatePicker} from "../DatePicker/GermanDatePicker";
import {FormGroup, Input} from "reactstrap";

const OrderDateTimePicker = props => {
    
    const [times, setTimes] = useState([])
    const [openToDate, setOpenToDate] = useState(new Date())
    
    useEffect(() => {
       // var burgers = cart.filter(o => o.main.id === 1).length;
        //var wings = cart.filter(o => o.main.id === 2).length;
        async function fetchData() {
            const response = await fetch("/hours/content?burgers=" + props.burgers + "&wings=" + props.wings + "&region=" + props.region);
            const json = await response.json();
            setTimes(json);
            props.onAvailableTimesChanged(json);
            if (json.length > 0 && Array.isArray(json)) {
                const ret = [];
                json.forEach(t => {
                    ret.push(new Date(t.validDay));
                });
                setOpenToDate(ret.reduce((a, b) => a < b ? a : b))
            }
        }
        fetchData();
    }, [props.region]);

    const getAvailableDates = () => {
        const ret = [];
        if (!Array.isArray(times)) return ret;
        times.forEach(t => {
            ret.push(new Date(t.validDay));
        });
        return ret;
    }

    const getAvailableTimes = () => {
        const ret = [];
        let date = props.date;
        if (!Array.isArray(times) || props.date === null) date = openToDate;
        console.log("Date", date)
        times.filter(t => new Date(t.validDay).toDateString() === date.toDateString()).forEach(a => {
            a.openHours.forEach(o => {
                ret.push(new Date().setHours(o.from.hour, o.from.minute));
            })
        })
        return ret;
    }

    return (
        <DatePicker
            onChange={props.onChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={props.timeIntervals}
            includeDates={getAvailableDates()}
            includeTimes={
                getAvailableTimes()
            }
            dateFormat="dd.MM.yyyy HH:mm"
            selected={props.date}
            timeCaption={props.lang === "en" ? "Time" : "Uhrzeit"}
            customInput={<Input id="startDate"
                                invalid={props.date != null && !props.isValid}/>}
            openToDate={openToDate.setHours(17, 0)}
            placeholderText={props.lang === "en" ? "Date and Time" : "Datum und Uhrzeit"}
            withPortal
        />
    )
}

OrderDateTimePicker.defaultProps = {
    region: 0,
    lang: "de",
    timeIntervals: 10
}

export default OrderDateTimePicker;