import React from "react";
import {currencyFormatter} from "../../util/currencyFormatter";

const PricingButton = props => {
    let classPostfix = props.selected ? "selected" : "";
    classPostfix += props.showDelete && props.amount && props.amount > 0 ? " withDelete" : "";
    const removeBtn = props.showDelete && props.amount && props.amount > 0 ? <RemoveButton onClick={props.onDecrease}/> : <></>;
    const amountText = props.amount && props.amount > 1 ? props.amount + "x " : "";
    return (
        <div className={"btn-group"}><button onClick={props.onIncrease} className={"baseBtn " + classPostfix}>{amountText}{props.text} {currencyFormatter(props.price, props.pricePrefix, props.priceSuffix)} {props.badge && <span dangerouslySetInnerHTML={{__html: props.badge}}></span>}</button>{removeBtn}</div>
    )
}
PricingButton.defaultProps = {
    pricePrefix: "(+",
    priceSuffix: ")"
}
const RemoveButton = props => (<button className={"deleteBtn"} onClick={props.onClick}>-</button>)
export default PricingButton;