import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import React, {useState} from "react";

const LanguagePicker = props => {
    const selectedLanguage = localStorage.getItem("lang");
    let currentLang = <LanguageFlag lang="de" height={"20px"}/>;
    
    if (selectedLanguage !== null){
        if (selectedLanguage === "en") currentLang = <LanguageFlag lang="en" height={"20px"}/> 
    } 
    
    const setLanguage = lang => {
        localStorage.setItem("lang", lang);
        localStorage.removeItem("currentOrderSheet");
        window.location.reload();
    }
    return (
        <UncontrolledDropdown>
            <DropdownToggle tag="div" className="nav-link" caret>{currentLang}</DropdownToggle>
            <DropdownMenu>
                <DropdownItem tag="div" onClick={() => setLanguage("de")}><LanguageFlag height={"20px"} lang="de"  withName/></DropdownItem>
                <DropdownItem tag="div" onClick={() => setLanguage("en")}><LanguageFlag height={"20px"} lang="en" withName/></DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

const LanguageFlag = props => {
    let name = props.lang;
    let flag = <></>
    switch (props.lang) {
        case "de": name="Deutsch"; flag=<img src={"/austria-flag-round-xs.png"} height={props.height} className={"mr-2"}/>; break;
        case "en": name="English"; flag=<img src={"/ireland-flag-round-xs.png"} height={props.height} className={"mr-2"}/>; break;
        default: break;
    }
    if (props.withName) return<>{flag}{name}</>;
    else return flag;
}

export default LanguagePicker;