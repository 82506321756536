import React, {Component} from 'react';
import {Table} from "reactstrap";

export class ReservationPage extends Component {
    static displayName = ReservationPage.name;

    state = {
        hours: []
    }

    lang = "de";

    constructor(props) {
        super(props);
        this.lang = localStorage.getItem("lang");
    }
    
    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML =
            "    _hors=[['eid','hydra-0011i000002k1bEAAQ'],['tagid','hors-hydra-0011i000002k1bEAAQ'],['width','100%'],['height','500'],['foregroundColor','white'],['backgroundColor','transparent'],['linkColor',''],['errorColor',''],['primaryButtonForegroundColor',''],['primaryButtonBackgroundColor',''],['secondaryButtonForegroundColor',''],['secondaryButtonBackgroundColor','']];\n" +
            "(function(d,\n" +
            "          t) {var e=d.createElement(t),s=d.getElementsByTagName(t)[0];    e.src = \"https://reservation.dish.co/widget.js\";    s.parentNode.insertBefore(e,s);}(document,\n" +
            "    'script'));\n";
        document.body.appendChild(s);
    }
    

    render() {
        const temp_style = {
            fontSize: "18px"
        }
        const title = this.lang === "en" ? "Reservations" : "Reservierung";
        const info = this.lang === "en" ? <div>By default, reservations are valid for <span className={"text-irish-highlight"}>2 hours</span>. If you already know that you would like to stay longer, please use the "Message" field in step 2 of the reservation to let us know :)</div>
    : <div>Wir planen für Reservierungen standardmäßig <span className={"text-irish-highlight"}>2 Stunden</span> ein. Falls ihr schon wisst, dass es bei euch länger dauern wird, gebt uns das bitte über das "Message"-Feld im zweiten Schritt der Reservierung bekannt :)</div>;
        return (
            <div>
                <h1>{title}</h1>
                <div style={temp_style}>{info}</div>
                <div id="hors-hydra-0011i000002k1bEAAQ"></div>
            </div>
        );
    }
}
