import React from "react";

const Impressum = props => {
    return (
        <div>
            <h3>Impressum</h3>

            Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63 Gewerbeordnung und
            Offenlegungspflicht laut §25 Mediengesetz.
            <br/><br/>
            <h5>Name des Einzelunternehmers:</h5>
            <p>Dino Karger</p>
            <h5>Rechtsform:</h5>
            <p>Einzelunternehmen</p>
            <h5>Unternehmensgegenstand:</h5>
            <p>Gastronomie</p>
            <h5>UID-Nummer:</h5>
            <p>ATU 57190239</p>
            <h5>Firmensitz bzw. volle geografische Anschrift:</h5>
            <p>Gleinker Gasse 14/16, 4400 Steyr, Ö̈sterreich</p>
            <h5>Volle geografische Anschrift der tatsächlichen Niederlassung (für behördliche und gerichtliche
                Zustellungen taugliche Anschrift)</h5>
            <p>The Irish, Gleinkergasse 14/16, 4400 Steyr. Und Beach Corner, Sportplatzstraße 5, 4452 Ternberg</p>
            <p>Tel.: <a href="tel:+43 7252/711 96 12">+43 7252/711 96 12</a></p>
            <p>E-Mail: <a href="mailto:dino@theirish.eu">dino@theirish.eu</a></p>
            <br/>
            <p>Gewerbeordnung: www.ris.bka.gv.at</p>
            <p>Angaben zur Online-Streitbeilegung: Verbraucher haben die Möglichkeit, Beschwerden an die
                Online-Streitbeilegungsplattform der EU zu richten. Sie können allfällige Beschwerde auch an die oben
                angegebene E-Mail-Adresse richten.</p>
            <p>Bildernachweis: Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
        </div>
    )
}

export default Impressum;