import React, {Component} from "react";
import {Input, Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ConfirmButton from "./../OrderForm/ConfirmButton";

export default class StatusViewModal extends Component {
    state = {
        textboxvalue: "",
        loading: false,
        status: null
    }

    constructor(props) {
        super(props);
        this.getStatusText = this.getStatusText.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
    }

    checkStatus = async () => {
        await this.setState({loading: true, status: null})
        let res = await fetch("/orders/status/" + this.state.textboxvalue);
        if (!res.ok) {
            this.setState({loading: false, status: {status: -1, time: "", type: -1}})
        }
        else {
            let text = await res.json();
            this.setState({loading: false, status: text})
        }
    }

    getStatusText = () => {
        let status = this.state.status
        if (status == null) return "-";
        if (this.props.lang == "en") {
            switch (status.status) {
                case -1:
                    return <div>Order not found!</div>
                case 0:
                    return <div>Order received.<br/>Please wait for confirmation. After we have confirmed the chosen time slot, the status will update to <strong>Order Confirmed</strong>.</div>
                case 1:
                    return <div>Order confirmed<br/><strong>Type:</strong> {status.type === 0 ? "Self-Pickup" : "Delivery"}<br/><strong>Time:</strong> {status.time}</div>
                case 2:
                    return (
                        <div>Time change requested!<br/><strong>Type:</strong> {status.type === 0 ? "Self-Pickup" : "Delivery"}<br/>
                        <strong>Requested new time:</strong> {status.time}<br/>If you did not receive an email/SMS with a confirmation link, you can also directly confirm or reject the change here. If you reject the change, your order will be canceled!<br/>
                        <a className="btn btn-success m-1" href={`/orders/confirm-move?id=${status.moveCode}`}>Confirm</a><a className="btn btn-danger m-1" href={`/orders/reject-move?id=${status.moveCode}`}>Reject & Cancel Order</a>
                        </div>)
                case 3:
                    return <div>Sorry, your order was canceled!</div>
                case 4:
                    return <div>Order Completed! Thanks and see you soon!</div>
                case 5:
                    return <div>Your order is on its ways and will arrive in a few minutes!</div>
                default:
                    return <div>-</div>
            }
        } else {
            switch (status.status) {
                case -1:
                    return <div>Bestellung nicht gefunden!</div>
                case 0:
                    return <div>Die Bestellung ist bei uns eingegangen!<br/>Sobald wir den Termin bestätigt haben, wird hier <strong>Bestellung bestätigt</strong> angezeigt.</div>
                case 1:
                    return <div>Bestellung bestätigt<br/><strong>Typ:</strong> {status.type === 0 ? "Selbstabholung" : "Lieferung"}<br/><strong>Zeit:</strong> {status.time}</div>
                case 2:
                    return (
                        <div>Zeitänderung angefragt!<br/>
                        <strong>Typ:</strong> {status.type === 0 ? "Selbstabholung" : "Lieferung"}<br/>
                        <strong>Angefragter neuer Zeitpunkt:</strong> {status.time}<br/>Falls du keine Mail/SMS zur Terminänderung erhalten hast, kannst du den neuen Termin auch hier bestätigen oder ablehen. Wenn die Terminverschiebung abgelehnt wird, wird die Bestellung storniert.<br/>
                            <a className="btn btn-success m-1" href={`/orders/confirm-move?id=${status.moveCode}`}>Bestätigen</a><a className="btn btn-danger m-1" href={`/orders/reject-move?id=${status.moveCode}`}>Ablehnen & Bestellung stornieren</a>
                        </div>)
                case 3:
                    return <div>Leider wurde deine Bestellung storniert!</div>
                case 4: 
                    return <div>Bestellung abgeschlossen! Danke und bis bald :)</div>
                case 5:
                    return <div>Deine Bestellung ist bereits unterwegs zu dir!</div>
                default:
                    return <div>-</div>
            }
        }
    }

    render() {
        console.log(this.state.status)
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                <ModalHeader>{this.props.lang == "en" ? "Check order status" : "Bestellstatusabfrage"}</ModalHeader>
                <ModalBody>
                    <Input value={this.state.textboxvalue}
                           style={{textAlign: "center"}}
                           onChange={e => this.setState({textboxvalue: e.target.value})}
                           placeholder={this.props.lang == "en" ? "Order Code" : "Bestellcode"}
                           type="text"
                    />
                    <Button className="mt-1 centered" color="success" onClick={() => this.checkStatus()}>{this.props.lang == "en" ? "Check" : "Abfragen"}</Button>
                    <br/><br/>
                    <h5>Status:</h5>
                    <div className="centered">{this.getStatusText()}</div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.toggle}>zurück</Button>
                </ModalFooter>
            </Modal>
        );
    }
}