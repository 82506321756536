import React from "react";

const Privacy = props => {
    return (
        <div>
            <h3>Datenschutzerklärung</h3>
            <p>Diese Datenschutzerklärung gilt für die Verarbeitung personenbezogener Daten von Kunden von The Irish Steyr bei Bestellungserfassung über das Bestellformular auf theirish.eu
            </p>
            <p>Reservierungen, die ebenfalls über diese Webseite vorgenommen werden können, werden von einem externen Dienstleister abgewickelt. Dessen Datenschutzerklärung finden Sie hier: <a href="https://reservation.dish.co/customer/privacy?cc=AT&eid=hydra-0011i000002k1bEAAQ">https://reservation.dish.co/customer/privacy?cc=AT&eid=hydra-0011i000002k1bEAAQ</a>.</p>
           
            <h5>Welche personenbezogenen Daten verarbeiten wir und aus welchem Grund?</h5>
            
            Wir verarbeiten personenbezogene Daten, wenn Sie unseren Bestelldienst in Anspruch nehmen. Dafür sind folgende Daten erforderlich:<br/>
            <br/>
            <ul style={{"listStyle": "initial"}}>
                <li>Inhalt der Bestellung</li>
                    <li>Name oder Bezeichnung zu jeder Bestelleinheit (zur Unterscheidung der einzelnen Bestelleinheiten)</li>
                <li>Vor- und Nachname</li>
                <li>Email-Adresse (zur Zusendung von Bestätigungs- und Informationsmails)</li>
                <li>Telefonnummer (zur Kontaktaufnahme bei Unklarheitheiten oder Problemem)</li>
                <li>Kommentar zur Bestellung (falls ausgefüllt)</li>
            </ul>
            <br/>
            sowie weiters bei Inanspruchnahme des optionalen Lieferdienstes (nicht immer Verfügbar):<br/>
            <ul style={{"listStyle": "initial"}}>
                <li>Lieferadresse</li>
            </ul>
            <br/>
            <h5>Auskunft und Anfordern gespeicherter Daten</h5>
            <p>Gerne erteilen wir Ihnen Auskunft über Ihre personenbezogenen Daten, die in unserem System erfasst sind. Bitte kontaktieren sie uns dazu per Email an dino@theirish.eu</p>
            <p>Wenn Sie die Löschung Ihrer Daten wünschen, wenden Sie sich bitte ebenfalls an die angeführte Email-Adresse.</p>
        </div>
    )
}

export default Privacy;