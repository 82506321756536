import React, {Component} from "react";
import {Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ConfirmButton from "./../OrderForm/ConfirmButton";

export default class SubmitResultModal extends Component {
    state = {}

    render() {
        if (this.props.lang === "en") {
            if (this.props.success) {
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>Done!</ModalHeader>
                        <ModalBody>
                            Thank you for your request, {this.props.customer.firstname}. Your Order Code is <br/><br/>
                            <div className={"centered"}><strong>{this.props.message}</strong></div>
                            <br/>
                            As soon as your request was confirmed, you will receive an Email confirmation to {this.props.customer.email}.<br/><br/>
                            In case you did not receive an Email, you can always enter your Order Code in the status tool on the Cart page to get an update on the current status.<br/><br/>
                            Please keep in mind that we only accept cash payments.
                        </ModalBody>
                        <ModalFooter>
                            <ConfirmButton onClick={this.props.toggle} activeText={"Understood"}/>
                        </ModalFooter>
                    </Modal>
                );
            } else {
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>Error :(</ModalHeader>
                        <ModalBody>
                            Unfortunately, something went wrong. Please try again or give us a call, if this issue
                            persists!
                            Contact Data and Opening Hours are available from <a
                            href={"www.theirish.eu"}>theirish.eu</a>
                        </ModalBody>
                        <ModalFooter>
                            <ConfirmButton activeText={"Ok"} onClick={this.props.toggle}/>
                        </ModalFooter>
                    </Modal>
                );
            }

        } else {
            if (this.props.success) {
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>Geschafft!</ModalHeader>
                        <ModalBody>
                            Danke für deine Bestellung, {this.props.customer.firstname}. Dein Bestellcode ist <br/><br/>
                            <div className={"centered"}><strong>{this.props.message}</strong></div>
                            <br/>
                            Sobald wir die Bestellung bestätigt haben, erhältst du eine Email-Bestätigung.<br/><br/>
                            Außerdem kannst du den aktuellen Status deiner Bestellung unter Verwendung des Bestellcodes auch jederzeit auf der Seite "Warenkorb" abfragen.
                            <br/><br/>
                            Bitte beachte, dass nur Barzahlungen möglich sind!
                        </ModalBody>
                        <ModalFooter>
                            <ConfirmButton onClick={this.props.toggle} activeText={"Verstanden"}/>
                        </ModalFooter>
                    </Modal>
                );
            } else {
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>Error :(</ModalHeader>
                        <ModalBody>
                            Leider ist etwas schief gelaufen. Das kann z.B. daran liegen, dass ein Teil deiner
                            Bestellung zum gewählten Zeitpunkt nicht mehr verfügbar ist. Bitte versuche es erneut oder
                            ruf uns an.
                            Nummer und Öffnungszeiten gibts auf <a href={"www.theirish.eu"}>theirish.eu</a>
                        </ModalBody>
                        <ModalFooter>
                            <ConfirmButton activeText={"Ok"} onClick={this.props.toggle}/>
                        </ModalFooter>
                    </Modal>
                );
            }
        }
    }
}