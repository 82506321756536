import React from 'react';
import {useState} from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Badge,
    DropdownToggle,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import {Link} from 'react-router-dom';
import './NavMenu.css';
import LanguagePicker from "./LanguagePicker";

export const NavMenu = props => {
    let displayName = NavMenu.name;
    const [navbarOpen, setNavbarOpen] = useState(false);
    const [path, setPath] = useState("");
    const selectedLanguage = localStorage.getItem("lang");
    if (path === "") setPath(window.location.pathname);
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3" dark>
                <Container>
                    <NavbarBrand tag={Link} to="/"><img src={"/the_irish.png"} height={"50px"}/></NavbarBrand>
                    <NavbarToggler onClick={() => setNavbarOpen(!navbarOpen)} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={navbarOpen}
                              navbar>
                        <ul className="navbar-nav flex-grow">
                            <NavItem>
                                <LanguagePicker/>
                            </NavItem>
                            <NavItem>
                                <NavLink onClick={() => setPath("/")} tag={Link} className={path === "/"  ? "text-light font-weight-bold" : "text-light"} to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} onClick={() => setPath("/reservation")} className={path.includes("reservation")  ? "text-light font-weight-bold" : "text-light"} to="/reservation">{selectedLanguage === "en" ? "Reservation" : "Reservierung"}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} onClick={() => setPath("/order")} className={path.includes("order")  ? "text-light font-weight-bold" : "text-light"}
                                         to="/order">{selectedLanguage === "en" ? "Order" : "Bestellen"}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} onClick={() => setPath("/cart")} className={path.includes("cart") ? "text-light font-weight-bold" : "text-light"} to="/cart">{selectedLanguage === "en" ? "Cart" : "Warenkorb"} <Badge
                                    color="secondary">{props.cartItems}</Badge></NavLink>
                            </NavItem>
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
            {(new Date() < new Date(2021, 6, 29, 0, 0, 0)) && <div className="notification-banner">{selectedLanguage == "en" ? "Due to construction work, our pub is closed between july 11 and july 28. Looking forward to meeting you again soon. :)" : 
            "Wegen Umbauarbeiten ist unser Pub zwischen 11.07. und 28.07. geschlossen. Wir freuen uns, euch danach wieder zahlreich begrüßen zu dürfen. :)"}</div>}
        </header>
    );
}
