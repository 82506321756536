import React, {Component} from 'react';
import {Container} from 'reactstrap';
import {NavMenu} from './NavMenu';
import {Footer} from "./Footer";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className={"baselayout"}>
                <NavMenu cartItems={this.props.cartItems}/>
                <Container>
                    {this.props.children}
                </Container>
                <Footer text={new Date().getFullYear() + " ©  The Irish Steyr"}
                        link_copyright="mailto:dino@theirish.eu"
                        link_version="mailto:felix.winterleitner@gmail.com"
                        version={"v2.11"}/>
            </div>
        );
    }
}
