import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

const GermanDatePicker = props => {
    registerLocale("de", de);
    return (
        <DatePicker
            {...props}
            showPopperArrow={false}
            locale="de"
        />
    );
};

export { GermanDatePicker };
