import React from "react";
import {Button} from "reactstrap"
import {currencyFormatter} from "../../util/currencyFormatter";
import ConfirmButton from "../OrderForm/ConfirmButton";

const OrderPriceBottom = props => {
    const overLimit = () => {
        return props.burgers > props.burgerLimit || props.wings > props.wingsLimit
    }
    return (
        <div className={"orderPriceBottom"}>
            <div className={"left"}>{currencyFormatter(props.price)}</div>
            {overLimit() ? 
                <div className={"right"}><Button color="danger" disabled>{props.lang === "en" ? `Max. ${props.burgerLimit} Burgers and ${props.wingsLimit} Wings per order!` : `Max. ${props.burgerLimit}x Burger und ${props.wingsLimit}x Wings pro Bestellung!`}</Button></div> :
                <div className={"right"}><ConfirmButton activeText={props.lang === "en" ? "Finish" : "Weiter"}
                                                        onClick={props.onClick} bordered/></div>
            }
        </div>
    )
}

export default OrderPriceBottom;