import React, {Component} from "react";
import {Form} from "reactstrap";
import MainSelector from "./MainSelector";
import ConfigurationSelector from "./ConfigurationSelector";
import DefaultIngredientRemover from "./DefaultIngredientRemover";

class OrderForm extends Component {
   /* state = {
        selectedMain: {
            id: 0,
            value: {},
            extras: []
        },
        selectedSides: [],
        selectedDips: []
    }*/

    state = {
        selectedMain: this.props.editOrder.main,
        selectedSides: this.props.editOrder.sides,
        selectedDips: this.props.editOrder.dips,
        selectedDrinks: this.props.editOrder.drinks
    }
    
    constructor(props) {
        super(props);
    }

    selectMain = main => {
        this.setState({selectedMain: {id: main.id, value: main, extras: [], defaultSelection: Array.isArray(main.defaultSelection) ? main.defaultSelection.map(s => {return {id: s.id, value: s, wasRemoved: false}}) : []}}, this.notifyOrderUpdate);
    }

    notifyOrderUpdate() {
        this.props.onOrderUpdate({
            main: this.buildSelectedMainDish(),
            sides: this.state.selectedSides,
            dips: this.state.selectedDips,
            drinks: this.state.selectedDrinks,
            price: this.getPrice()
        })
    }

    changeOptionAmount = (newOption, amountDifference) => {
        let {selectedMain} = this.state;
        const index = selectedMain.extras.findIndex(x => x.id === newOption.id);
        let option = selectedMain.extras[index];
        if (index !== -1) {
            if (Object.keys(option).includes("amount")) {
                if (option.amount + amountDifference >= 0) option.amount += amountDifference;
            }
        } else {
            option = selectedMain.extras[selectedMain.extras.push({}) - 1];
        }
        option.id = newOption.id;
        option.value = {...newOption};
        if (!Object.keys(option).includes("amount") && amountDifference > 0) {
            if (amountDifference > 0)
                option.amount = amountDifference;
            else option.amount = 0;
        }
        if (option.amount === 0)
            selectedMain.extras = selectedMain.extras.filter(o => o.id !== newOption.id);
        this.setState({selectedMain: selectedMain}, this.notifyOrderUpdate);
    }

    changeSidesAmount = (newSide, amountDifference) => {
        let {selectedSides} = this.state;
        const index = selectedSides.findIndex(x => x.id === newSide.id);
        let option = selectedSides[index];
        if (index !== -1) {
            if (Object.keys(option).includes("amount")) {
                if (option.amount + amountDifference >= 0) option.amount += amountDifference;
            }
        } else {
            option = selectedSides[selectedSides.push({}) - 1];
        }
        option.id = newSide.id;
        option.value = {...newSide};
        if (!Object.keys(option).includes("amount") && amountDifference > 0) {
            if (amountDifference > 0)
                option.amount = amountDifference;
            else option.amount = 0;
        }
        if (option.amount === 0)
            selectedSides = selectedSides.filter(o => o.id !== newSide.id);
        this.setState({selectedSides: selectedSides}, this.notifyOrderUpdate);
    }

    changeDipsAmount = (newDip, amountDifference) => {
        let {selectedDips} = this.state;
        const index = selectedDips.findIndex(x => x.id === newDip.id);
        let option = selectedDips[index];
        if (index !== -1) {
            if (Object.keys(option).includes("amount")) {
                if (option.amount + amountDifference >= 0) option.amount += amountDifference;
            }
        } else {
            option = selectedDips[selectedDips.push({}) - 1];
        }
        option.id = newDip.id;
        option.value = {...newDip};
        if (!Object.keys(option).includes("amount") && amountDifference > 0) {
            if (amountDifference > 0)
                option.amount = amountDifference;
            else option.amount = 0;
        }
        if (option.amount === 0)
            selectedDips = selectedDips.filter(o => o.id !== newDip.id);
        this.setState({selectedDips: selectedDips}, this.notifyOrderUpdate);
    }

    changeDrinksAmount = (newDrink, amountDifference) => {
        let {selectedDrinks} = this.state;
        const index = selectedDrinks.findIndex(x => x.id === newDrink.id);
        let option = selectedDrinks[index];
        if (index !== -1) {
            if (Object.keys(option).includes("amount")) {
                if (option.amount + amountDifference >= 0) option.amount += amountDifference;
            }
        } else {
            option = selectedDrinks[selectedDrinks.push({}) - 1];
        }
        option.id = newDrink.id;
        option.value = {...newDrink};
        if (!Object.keys(option).includes("amount") && amountDifference > 0) {
            if (amountDifference > 0)
                option.amount = amountDifference;
            else option.amount = 0;
        }
        if (option.amount === 0)
            selectedDrinks = selectedDrinks.filter(o => o.id !== newDrink.id);
        this.setState({selectedDrinks: selectedDrinks}, this.notifyOrderUpdate);
    }


    buildSelectedMainDish() {
        let target = {};
        target.id = this.state.selectedMain.id;
        target.value = this.state.selectedMain.value;
        target.name = this.state.selectedMain.value.name;
        target.extras = [...this.state.selectedMain.extras];
        target.defaultSelection = [...this.state.selectedMain.defaultSelection]
        return target;
    }

    getPrice() {
        let price = 0;
        price += isNaN(this.state.selectedMain.value.price) ? 0 : this.state.selectedMain.value.price;
        price += this.state.selectedMain.extras.reduce((a, b) => a + b.amount * b.value.price, 0);
        if (this.state.selectedMain.id == null || this.state.selectedMain.id === 0) {
            price += this.state.selectedSides.reduce((a, b) => a + b.amount * (b.value.price + this.props.configuration.sidesOnlyExtraPrice), 0);
        }
        else {
            price += this.state.selectedSides.reduce((a, b) => a + b.amount * b.value.price, 0);
        }
        //Add price of all dips, then substract the free dips
        price += this.state.selectedDips.reduce((a, b) => a + b.amount * b.value.price, 0);
        let dipPrices = [];
        this.state.selectedDips.forEach(o => {
            for (let c = 0; c < o.amount; c++) {
                dipPrices.push(o.value.price);
            }
        })
        price -= dipPrices.sort((a, b) => a < b).slice(0, this.getIncludedDips()).reduce((a, b) => a + b, 0);
        //
        //Subtract Free Cheese
        let cheesePrices = [];
        this.state.selectedMain.extras.filter(o => o.value.type === "cheese").forEach(o => {
            for (let c = 0; c < o.amount; c++) {
                cheesePrices.push(o.value.price);
            }
        })
        price -= cheesePrices.sort((a, b) => a < b).slice(0, this.getIncludedCheese()).reduce((a, b) => a + b, 0);
        
        //Add drink prices
        price += this.state.selectedDrinks.reduce((a, b) => a + b.amount * b.value.price, 0);
        return price;
    }

    getIncludedCheese() {
        let cheese = 0;
        cheese += isNaN(this.state.selectedMain.value.includedCheese) ? 0 : this.state.selectedMain.value.includedCheese;
        cheese += this.state.selectedMain.extras.reduce((a, b) => a + b.amount * b.value.includedCheese, 0);
        cheese += this.state.selectedSides.reduce((a, b) => a + b.amount * b.value.includedCheese, 0);
        return cheese;
    }

    getIncludedDips() {
        let dips = 0;
        dips += isNaN(this.state.selectedMain.value.includedDips) ? 0 : this.state.selectedMain.value.includedDips;
        dips += this.state.selectedMain.extras.reduce((a, b) => a + b.amount * b.value.includedDips, 0);
        dips += this.state.selectedSides.reduce((a, b) => a + b.amount * b.value.includedDips, 0);
        return dips;
    }

    getIncludedItems(category) {
        if (category === "cheese") return this.getIncludedCheese();
        else return null;
    }

    renderExtraSelectors = () => {
        const selectors = [];
        const mainDish = this.state.selectedMain.value;
        if (Object.keys(mainDish).length === 0 || mainDish.id === 0) return selectors;
        selectors.push(mainDish.requirements.map(r => r.typeName !== "default" && <ConfigurationSelector
            lang={this.props.lang}
            dishes={mainDish.choices[r.typeName]}
            selection={this.state.selectedMain.extras}
            onChangeOption={this.changeOptionAmount}
            min={r.minimumTotal}
            max={r.maximumTotal}
            maxPerItem={r.maximumUnique}
            includedInPriceCount={this.getIncludedItems(r.typeName)}
            title={r.displayTitle}
            key={r.displayTitle}/>
        ));
        return selectors;
    }
    
    onDefaultIngredientClicked = (id) => {
        let ing = this.state.selectedMain.defaultSelection.find(s => s.value.id === id);
        if (typeof ing === "undefined" || ing == null) return;
        const main = this.state.selectedMain;
        let dfs = main.defaultSelection.filter(s => s.value.id !== id);
        let newIng = Object.assign({}, ing);
        newIng.wasRemoved = !ing.wasRemoved;
        dfs.push(newIng);
        dfs.sort((x,y) => x.value.id > y.value.id);
        this.setState({selectedMain: {...main, defaultSelection: dfs}}, this.notifyOrderUpdate);
    }

    render() {
        return <div>
            <h5>{this.props.lang === "en" ? "Main Dish" : "Hauptgericht"}</h5>
            <MainSelector mains={this.props.menu.mains} selected={this.state.selectedMain.id}
                          onSelect={this.selectMain} lang={this.props.lang}/><br/>
            {this.state.selectedMain.defaultSelection && this.state.selectedMain.defaultSelection.length > 0 ? <DefaultIngredientRemover title={this.props.lang === "en" ? "Default Ingredients" : "Standardzutaten"} defaultIngredients={this.state.selectedMain.defaultSelection} onIngredientClicked={this.onDefaultIngredientClicked}/> : <></>}
            {this.renderExtraSelectors().map(x => (<div key={Math.random()}><br/>
                {x}
                <hr/>
            </div>))}<br/>
            <ConfigurationSelector title={this.props.lang === "en" ? "Sides" : "Beilagen"} titleSize={5} dishes={this.props.menu.sides} selection={this.state.selectedSides}
                                   onChangeOption={this.changeSidesAmount} increasePriceBy={this.state.selectedMain.id === 0 ? this.props.configuration.sidesOnlyExtraPrice : 0} lang={this.props.lang}/>
            <ConfigurationSelector title={"Dips"} titleSize={5} dishes={this.props.menu.dips} selection={this.state.selectedDips}
                                   includedInPriceCount={this.getIncludedDips()}
                                   onChangeOption={this.changeDipsAmount} lang={this.props.lang}/>
            <ConfigurationSelector title={"Drinks"} titleSize={5} dishes={this.props.menu.drinks} selection={this.state.selectedDrinks}
                                   includedInPriceCount={0}
                                   onChangeOption={this.changeDrinksAmount} lang={this.props.lang}/>
        </div>
    }
}

export default OrderForm;