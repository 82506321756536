import React, {Component} from "react";
import DefaultIngredientButton from "./DefaultIngredientButton";

const DefaultIngredientRemover = props => {
    const options = [];
    props.defaultIngredients.map(m => options.push(
        <DefaultIngredientButton text={m.value.name}
                                 key={m.value.id}
                                 removed={m.wasRemoved}
                                 onClick={() => props.onIngredientClicked(m.value.id)}
        />
    ));
    return <div className={"defaultIngredientRemover"}><h6>{props.title}</h6>{options}</div>;
}

export default DefaultIngredientRemover;