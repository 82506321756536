import React, {Component, useEffect, useState} from 'react';
import OrderForm from "./OrderForm/OrderForm";
import OrderSheetSummary from "./OrderForm/OrderSheetSummary";
import ConfirmButton from "./OrderForm/ConfirmButton";
import ConfirmModal from "./OrderForm/ConfirmModal";
import {Link} from "react-router-dom";
import {DefaultOrder} from "./OrderForm/DefaultOrder";
import { withRouter } from "react-router-dom";

export const OrderPage = props => {
    const [menu, setMenu] = useState({
        mains: [],
        sides: [],
        dips: [],
        drinks: []
    })
    const [loading, setLoading] = useState(true)
    const [orderSheetKey, setOrderSheetKey] = useState(1)
    const [ordersheet, setOrdersheet] = useState(DefaultOrder())
    const [modalOpen, setModalOpen] = useState(false)
    const [modalNameInput, setModalNameInput] = useState("")
    const [configuration, setConfiguration] = useState({
        sidesOnlyExtra: 0.6,
        burgerLimit: 8,
        wingsLimit: 4
    })
    const [selectedLanguage, setSelectedLanguage] = useState("de")
    
    useEffect(() => {
        if (props.editOrder) {
            setMenu({
                mains: [],
                sides: [],
                dips: [],
                drinks: []
            })
            setConfiguration( {
                sidesOnlyExtra: 0.6,
                burgerLimit: 8,
                wingsLimit: 4
            })
            setLoading(true)
            setOrdersheet(props.editOrder)
        }
        setSelectedLanguage(localStorage.getItem("lang"))
        loadMenuData()
    }, [])

    const loadMenuData = async () => {
        const response = await fetch('menu?lang=' + selectedLanguage);
        const data = await response.json();
        const config = data.configuration;
        delete data.configuration;
        setMenu(data)
        setConfiguration(config)
        setLoading(false)
    }

    const onOrderSheetDidUpdate = (ordersheet) => {
        setOrdersheet(ordersheet)
        localStorage.setItem("currentOrderSheet", JSON.stringify(ordersheet));
        localStorage.setItem("lastUsage", new Date());
    }

    const orderComplete = () => {
        if ((Object.keys(ordersheet).length === 0 && ordersheet.constructor === Object) || (ordersheet.main.id === 0 && (ordersheet.sides.length > 0 ||ordersheet.dips.length > 0 ||ordersheet.drinks.length > 0))) return true;
        const main = menu.mains.find(m => m.id === ordersheet.main.id);
        if (!(typeof main === "object")) return false; //check if found, otherwise probably error in code
        let ret = true;
        main.requirements.forEach(r => {
            if (r.minimumTotal === 0 && r.maximumTotal === 0 && r.maximumUnique === 0) return;
            const total = ordersheet.main.extras.filter(o => o.value.type === r.typeName).reduce((a, b) => a + b.amount, 0);
            const maxSingle = Math.max(ordersheet.main.extras.filter(o => o.value.type === r.typeName).map(x => x.amount));
            if (total < r.minimumTotal || (total > r.maximumTotal && r.maximumTotal > 0) || (maxSingle > r.maximumUnique && r.maximumUnique > 0)) ret = false;
        });
        return ret;
    }

    const onAddToCartClickedHandler = () => {
        setModalOpen(prev => !prev)
    }

    const onAddToCartConfirmedClickedHandler = (name) => {
        setModalOpen(false)
        let currentOrder;
        try {
            currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
        } catch (e) {
            currentOrder = [];
        }
        if (currentOrder === null) currentOrder = [];
        let orderSheet = JSON.parse(JSON.stringify(ordersheet));
        orderSheet.name = name;
        orderSheet.randomId = Math.random();
        currentOrder.push(orderSheet);
        localStorage.setItem("currentOrder", JSON.stringify(currentOrder));
        localStorage.removeItem("currentOrderSheet");
        setOrdersheet(DefaultOrder())
        setOrderSheetKey(props.orderSheetKey + 1)
        props.onSubmit();
    }

    const onSaveEdited = () => {
        let currentOrder;
        try {
            currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
        } catch (e) {
            currentOrder = [];
        }
        if (currentOrder === null) currentOrder = [];
        let orderSheet = JSON.parse(JSON.stringify(ordersheet));
        orderSheet.name = localStorage.getItem("editName");
        orderSheet.randomId = localStorage.getItem("editId");
        currentOrder = currentOrder.filter(o => o.randomId != localStorage.getItem("editId"))
        currentOrder.push(orderSheet);
        localStorage.setItem("currentOrder", JSON.stringify(currentOrder));
        localStorage.removeItem("currentOrderSheet");
        localStorage.removeItem("isEdit");
        localStorage.removeItem("editName");
        localStorage.removeItem("editId");
        setOrdersheet(DefaultOrder())
        setOrderSheetKey(props.orderSheetKey + 1)
        props.onSubmit();
        props.history.push("cart");
    }

    const resetOrderSheet = () => {
        localStorage.removeItem("currentOrderSheet");
        localStorage.removeItem("isEdit");
        localStorage.removeItem("editName");
        localStorage.removeItem("editId");
        setOrdersheet(DefaultOrder())
        setOrderSheetKey(props.orderSheetKey + 1);
    }

    const isEdit = !(localStorage.getItem("isEdit") == null)
    let contents = loading
        ? <p><em>Loading...</em></p>
        : <OrderForm key={orderSheetKey} menu={menu} onOrderUpdate={onOrderSheetDidUpdate}
                     editOrder={ordersheet} lang={selectedLanguage} configuration={configuration}/>;

    return (
        <>
            <ConfirmModal key={orderSheetKey+1} isOpen={modalOpen} toggle={onAddToCartClickedHandler} onConfirm={onAddToCartConfirmedClickedHandler} lang={selectedLanguage}/>
            <div className="orderPage-container">
                <div className={"orderForm-container"}>
                    {isEdit ?
                        <h1 id="tabelLabel">{selectedLanguage === "en" ? "Modify your order" : "Bestellung bearbeiten"}<button className="btn btn-sm btn-secondary ml-1" onClick={resetOrderSheet}>Reset</button></h1> :
                        <h1 id="tabelLabel">{selectedLanguage === "en" ? "Configure your order" : "Bestellung konfigurieren"}<button className="btn btn-sm btn-secondary ml-1" onClick={resetOrderSheet}>Reset</button></h1>
                    }
                    <span>{selectedLanguage === "en" ? (<>Add items to your order or go to <strong><Link to={"/cart"} className="text-light">Cart</Link></strong>.</>):(<>Füge deiner Bestellung einen Eintrag hinzu oder gehe zum <strong><Link to={"/cart"} className="text-light">Warenkorb</Link></strong>.</>)}</span><br/>
                    <p>{selectedLanguage === "en" ? (<>At the moment, you can add a maximum of <strong className={"text-irish-highlight"}>{configuration.burgerLimit} burgers</strong> and <strong className={"text-irish-highlight"}>{configuration.wingsLimit} wings</strong> to a single order.</>):(<>Pro Bestellung sind maximal <strong className={"text-irish-highlight"}>{configuration.burgerLimit} Burger</strong> und <strong className={"text-irish-highlight"}>{configuration.wingsLimit} Wings</strong> möglich.</>)}</p>

                    {contents}
                </div>
                <div className="bottomBanner-container">
                    <OrderSheetSummary main={ordersheet.main} sides={ordersheet.sides} drinks={ordersheet.drinks}
                                       dips={ordersheet.dips} price={ordersheet.price} showSum title={selectedLanguage === "en" ? "Summary" : "Zusammenfassung"} lang={selectedLanguage}/>
                    {isEdit ? <ConfirmButton disabled={!orderComplete()} activeText={selectedLanguage === "en" ? "Save" : "Änderung speichern"}
                                             disabledText={selectedLanguage === "en" ? "Configuration invalid :(" : "Wähle bitte zuerst etwas aus :)"} bordered
                                             onClick={onSaveEdited}/>
                        : <ConfirmButton disabled={!orderComplete()} activeText={selectedLanguage === "en" ? "Add to Cart" : "In den Warenkorb"}
                                         disabledText={selectedLanguage === "en" ? "Configuration invalid :(" : "Wähle bitte zuerst etwas aus :)"} bordered
                                         onClick={onAddToCartClickedHandler}/>}
                </div>
            </div>
        </>
    );
}