import React from "react";
import {currencyFormatter} from "../../util/currencyFormatter";

const OrderSheetSummary = props => {
    let list = [];
    if(props.main != null && Object.keys(props.main).length > 0) 
        list.push(<SummaryListItem key={"main"} title={props.main.name} titleAppendix={props.main.defaultSelection.filter(s => s.wasRemoved)} options={props.main.extras} lang={props.lang}/>);
    if(props.sides != null && props.sides.length > 0) 
           list.push(<SummaryListItem key={"sides"} title={props.lang === "en" ? "Sides" : "Beilagen"} options={props.sides}/>);
    if(props.dips != null && props.dips.length > 0)
        list.push(<SummaryListItem key={"dips"} title="Dips" options={props.dips}/>);
    if(props.drinks != null && props.drinks.length > 0)
        list.push(<SummaryListItem key={"drinks"} title="Drinks" options={props.drinks}/>);

    const sum = props.showSum ? <div className={"orderSummary-sum"}><h4>{props.lang === "en" ? "Total:" : "Summe:"} {currencyFormatter(props.price)}</h4></div> : "";
    return (
        <div>
            <h5>{props.personName}{props.title}</h5>
            {list}
            <hr/>
            {sum}
        </div>
    )
}

const SummaryListItem = props => {
    if (props.title == null || props.title.length === 0) return <></>;
    if (typeof props.options !== "object") 
        return <div><h6>{props.title}</h6></div>
    let removed = "";
    if (Array.isArray(props.titleAppendix) && props.titleAppendix.length > 0) {
        removed = props.lang === "en" ? " (without " : " (ohne ";

        props.titleAppendix.map((a, index) => {
            if (index > 0) removed += ", ";
            removed += a.value.name;
        })

        removed += ")";
    }
    return (
        <div>
            <h6>{props.title}{removed}</h6>
            <ul>
                {props.options.map(x => <li key={x.value.id}>{x.amount > 1 ? x.amount +"x " : ""}{x.value.name}</li>)}</ul>
        </div>
    )
}

export default OrderSheetSummary;