import React, {Component} from "react";
import MainButton from "./MainButton";

const MainSelector = props => {
    const mains = [];
    mains.push(<MainButton key={0} text={props.lang === "en" ? "None" : "Keines"} selected={props.selected === 0} onClick={() => props.onSelect({id:0})}/>);
    props.mains.map(m => mains.push(<MainButton key={m.id} text={m.name} selected={props.selected === m.id} price={m.price}
                                                onClick={() => props.onSelect(m)} badge={m.badge}/>));
    return mains;
}

export default MainSelector;