import React, {useState, useEffect} from "react";
import {Button} from "reactstrap"
import OrderSheetSummary from "./OrderForm/OrderSheetSummary";
import FinishModal from "./Cart/FinishModal";
import SubmitResultModal from "./Cart/SubmitResultModal";
import {currencyFormatter} from "../util/currencyFormatter";
import OrderPriceBottom from "./Cart/OrderPriceBottom";
import StatusViewModal from "./Cart/StatusViewModal";


const Cart = props => {
    let cart = [];

    const [times, setTimes] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [submitResultModalOpen, setSubmitResultModalOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [statusToolOpen, setStatusToolOpen] = useState(false);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [customer, setCustomer] = useState({firstname: "", lastname: "", email: ""})
    const [message, setMessage] = useState("");
    const [checkedPrice, setCheckedPrice] = useState(0);
    const [enableDeliver, setEnableDeliver] = useState(false);
    const [burgerLimit, setBurgerLimit] = useState(8);
    const [wingsLimit, setWingsLimit] = useState(4);
    const selectedLanguage = localStorage.getItem("lang");

    useEffect(() => {
        var burgers = cart.filter(o => o.main.id === 1).length;
        var wings = cart.filter(o => o.main.id === 2).length;
        async function fetchData() {
            const response = await fetch("/menu");
            const json = await response.json();
            setEnableDeliver(json.configuration.enableDeliver);
            setBurgerLimit(json.configuration.burgerLimit);
            setWingsLimit(json.configuration.wingsLimit);
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        async function validate() {

            let payload = {
                cart: cart
            }
            const response = await fetch('orders/validate', {
                method: 'post',
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const json = await response.json();
            const responseOK = response.ok;
            if (responseOK) {
                setCheckedPrice(json.price)
            }
        }

        validate();
    }, [cart])

    try {
        cart = JSON.parse(localStorage.getItem("currentOrder"));
        if (!Array.isArray(cart)) cart = [];
        cart.forEach(c => {
            /*delete c.main.value.meatChoices;
            delete c.main.value.cheeseChoices;
            delete c.main.value.breadChoices;
            delete c.main.value.extraChoices;
            delete c.main.value.defaultSelection;
            delete c.main.value.requirements;*/
        });
        
    } catch (e) {
        console.log("Failed getting cart");
        cart = []
    }
    
    const onEditClickedHandler = order => {
        localStorage.setItem("currentOrderSheet", JSON.stringify(order));
        localStorage.setItem("editName", order.name);
        localStorage.setItem("editId", order.randomId);
        localStorage.setItem("lastUsage", new Date());
        localStorage.setItem("isEdit", "true");
        props.history.push("order");
    }
    
    const onRemoveClickedHandler = (order) => {
        const remove = JSON.stringify(order);
        if (order.randomId == localStorage.getItem("editId")) {
            localStorage.removeItem("currentOrderSheet")
            localStorage.removeItem("isEdit");
            localStorage.removeItem("editName");
            localStorage.removeItem("editId");
        }
        let currentOrder;
        try {
            currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
        } catch (e) {
            currentOrder = [];
        }
        if (currentOrder === null) currentOrder = [];
        currentOrder = currentOrder.filter(x => JSON.stringify(x) !== remove);
        localStorage.setItem("currentOrder", JSON.stringify(currentOrder));
        props.onSubmit();
    }

    function uuidv4() {
        return 'xxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    
    function prepareCart(cart) {
        let C = JSON.parse(JSON.stringify(cart))
        C.forEach(c => {
            delete c.main.value.meatChoices;
            delete c.main.value.cheeseChoices;
            delete c.main.value.breadChoices;
            delete c.main.value.extraChoices;
            delete c.main.value.defaultSelection;
            delete c.main.value.requirements;
            delete c.main.value.choices;
        });
        return C
    }

    async function onSubmitFinishedHandler(finishModalData) {
        setModalOpen(false);
        setCustomer(finishModalData.customer)
        setSubmitting(true);
        let payload = {
            ...finishModalData,
            rand: uuidv4(),
            cart: prepareCart(cart)
        }
        const response = await fetch('orders?lang=' + selectedLanguage, {
            method: 'post',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const json = await response.json();
        const responseOK = response.ok;
        setOrderSuccess(responseOK);
        setSubmitting(false);
        setSubmitResultModalOpen(true);
        console.log("[Results]", responseOK, response);
        if (responseOK) {
            setMessage(json.id);
            localStorage.removeItem("currentOrder");
            localStorage.removeItem("currentOrderSheet");
        } else {
            setMessage(json.message);
        }
    }


    let cartModule = (<div>{cart.map(o =>
        <div className={"cart-item"} key={o.randomId}>
            <button className={"baseBtn removeOrderBtn right"} onClick={() => onRemoveClickedHandler(o)}>{selectedLanguage === "en" ? "Remove" : "Löschen"}
            </button>
            <button className={"baseBtn btn-secondary right"} onClick={() => onEditClickedHandler(o)}>Bearbeiten</button>
            <OrderSheetSummary main={o.main} sides={o.sides} drinks={o.drinks}
                               dips={o.dips} price={o.price} personName={o.name} lang={selectedLanguage}/>
        </div>
    )}
        <OrderPriceBottom price={checkedPrice} onClick={() => setModalOpen(true)} lang={selectedLanguage} 
                          burgers={cart.filter(o => o.main.id === 1).length} wings={cart.filter(o => o.main.id === 2).length}
                          burgerLimit={burgerLimit} wingsLimit={wingsLimit}
        />
    </div>);
    if (cart.length < 1)
        cartModule = <h5>{selectedLanguage === "en" ? "Cart is empty!" : "Noch keine leckeren Einträge gefunden..."}</h5>

    if (submitting) {
        return (
            <div>
                Lade...
            </div>
        )
    }
    return (
        <div>
            <SubmitResultModal isOpen={submitResultModalOpen}
                               toggle={() => setSubmitResultModalOpen(!submitResultModalOpen)} success={orderSuccess}
                               customer={customer} message={message} lang={selectedLanguage}/>
            <FinishModal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}
                         onConfirm={(data) => onSubmitFinishedHandler(data)} enableDeliver={enableDeliver} lang={selectedLanguage}
                         burgers={cart.filter(o => o.main.id === 1).length} wings={cart.filter(o => o.main.id === 2).length} price={checkedPrice}
            />
            <StatusViewModal lang={selectedLanguage} toggle={() => setStatusToolOpen(!statusToolOpen)} isOpen={statusToolOpen}/>
            <h1 id="tabelLabel">{selectedLanguage === "en" ? "Order Summary" : "Bestellübersicht"} <Button color="warning" onClick={() => setStatusToolOpen(true)}>{selectedLanguage == "en" ? "Check order status" : "Statusabfrage"}</Button></h1>
            <p>{selectedLanguage === "en" ? "Review your order and submit." : "Bestellung überprüfen und absenden."} <strong>{selectedLanguage === "en" ? "Attention: Cash Payments only!" : "Achtung: Nur Barzahlung möglich!"}</strong></p>
            {cartModule}
        </div>
    )
}

export default Cart;