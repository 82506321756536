import React, {Component} from "react";
import PricingButton from "./PricingButton";

const ConfigurationSelector = props => {
    const options = [];

    const onValueChangedHandler = (value, diff) => {
        let selectedCount = props.selection.filter(x => typeof props.dishes.find(d => d.id === x.id) === "object").reduce((a, b) => a + b.amount, 0);
        const valueCount = props.selection.filter(x => x.id === value.id).reduce((a, b) => a + b.amount, 0);
        if (props.maxPerItem > 0 && props.maxPerItem < valueCount + diff) return; //don't add more items than permitted, ignore if maxPerItem is 0
        if (!props.max || props.max <= 0 || diff <= 0) {
            props.onChangeOption(value, diff);
            return;
        }
        if (selectedCount + diff <= props.max) {
            props.onChangeOption(value, diff);
        } else {
            //try to find another element, decrease it and then increase the desired object
            const notZeroElement = props.selection.filter(x => typeof props.dishes.find(d => d.id === x.id) === "object").find(x => x.amount > 0);
            if (notZeroElement != null) {
                props.onChangeOption(notZeroElement.value, -1);
                props.onChangeOption(value, 1);
            }
        }

    }
    const categories = [...new Set(props.dishes.map(d => d.drinkCategory != null ? d.drinkCategory.name : null))]
    const drinkCategoryCount = categories.filter(c => c != null).length;
    let selectedCount = props.selection.filter(x => typeof props.dishes.find(d => d.id === x.id) === "object").reduce((a, b) => a + b.amount, 0);
    if (drinkCategoryCount > 1) {
        let categoriesWithDrinks = Object.fromEntries(categories.map(c => [c, []]))
        for (const d of props.dishes) {
            const cat = d.drinkCategory != null ? d.drinkCategory.name : null
            categoriesWithDrinks[cat].push(d)
        }
        Object.keys(categoriesWithDrinks).sort((a,b) => {
            if (a == "null") return 1
            if (b == "null") return -1
            return a > b
        }).map(c => {
            if (c != "null") {
                options.push(<h6 style={{marginTop: "15px"}}>{c}</h6>)
            }
            else options.push(<h6 style={{marginTop: "15px"}}>{props.lang == "en" ? "Uncategorized" : "Ohne Kategorie"}</h6>)
            categoriesWithDrinks[c].sort((a,b) => a.name > b.name).map(m => options.push(<PricingButton text={m.name}
                                                                         price={selectedCount < props.includedInPriceCount ? 0 : m.price + props.increasePriceBy}
                                                                         selected={typeof props.selection.find(o => o.id === m.id) === "object"}
                                                                         amount={props.selection.find(o => o.id === m.id)?.amount ?? 0}
                                                                         showDelete={props.max != 1}
                                                                         pricePrefix={props.pricePrefix}
                                                                         priceSuffix={props.priceSuffix}
                                                                         onIncrease={() => onValueChangedHandler(m, 1)}
                                                                         onDecrease={() => onValueChangedHandler(m, -1)}
                                                                         badge={m.badge}
                                                                         key={Math.random()}/>))
        })
        
    }
    else {
        props.dishes.map(m => options.push(<PricingButton text={m.name}
                                                          price={selectedCount < props.includedInPriceCount ? 0 : m.price + props.increasePriceBy}
                                                          selected={typeof props.selection.find(o => o.id === m.id) === "object"}
                                                          amount={props.selection.find(o => o.id === m.id)?.amount ?? 0}
                                                          showDelete={props.max != 1}
                                                          pricePrefix={props.pricePrefix}
                                                          priceSuffix={props.priceSuffix}
                                                          onIncrease={() => onValueChangedHandler(m, 1)}
                                                          onDecrease={() => onValueChangedHandler(m, -1)}
                                                          badge={m.badge}
                                                          key={Math.random()}/>));
    }
    return <div className={"configurationSelector"}>
        <ConfigurationTitle level={props.titleSize}>{props.title}{(props.min && props.min > 0) ?
            <small> {props.lang === "en" ? "Required" : "Erforderlich"}</small> : ""}{props.includedInPriceCount > 0 ?
            <small> ({props.includedInPriceCount} {props.lang === "en" ? "included" : "inkludiert"})</small>
            : ""}
        </ConfigurationTitle>
        {options}
    </div>;
}

const ConfigurationTitle = props => {
    switch (props.level) {
        case 1:
            return <h1>{props.children}</h1>
        case 2:
            return <h2>{props.children}</h2>
        case 3:
            return <h3>{props.children}</h3>
        case 4:
            return <h4>{props.children}</h4>
        case 5:
            return <h5>{props.children}</h5>
        case 6:
            return <h6>{props.children}</h6>
        case 7:
            return <h7>{props.children}</h7>
        default:
            return <h6>{props.children}</h6>
    }
}

ConfigurationSelector.defaultProps = {
    increasePriceBy: 0 //this prop is passed to the sides list when no main is selected => sides are more expensive when no main is selected
}

export default ConfigurationSelector;