import React from "react";
import {Button} from "reactstrap";
import {currencyFormatter} from "../../util/currencyFormatter";

const MainButton = props => {
    const classPostfix = props.selected ? "selected" : "";
    return (
        <button onClick={props.onClick} className={"baseBtn " + classPostfix}>{props.text} {props.price > 0 && currencyFormatter(props.price, "(", ")")} {props.badge && <span dangerouslySetInnerHTML={{__html: props.badge}}></span>}</button>
    )
}
export default MainButton;