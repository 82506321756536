import React, {Component} from 'react';
import {Route} from 'react-router';
import {Layout} from './components/Layout/Layout';
import {Home} from './components/Home';

import './custom.css'
import "./components/OrderForm/Buttons.css";
import {OrderPage} from "./components/OrderPage";
import Cart from "./components/Cart";
import {DefaultOrder} from "./components/OrderForm/DefaultOrder";
import {ResetPage} from "./components/ResetPage";
import {ReservationPage} from "./components/ReservationPage";
import Privacy from "./components/Privacy";
import Impressum from "./components/Impressum";
import {Routes} from "react-router-dom";

export default class App extends Component {
    static displayName = App.name;

    state = {
        cartItems: 0
    }

    constructor(props) {
        super(props);
        this.getCartContent = this.getCartContent.bind(this);
        this.getCurrentOrderSheet = this.getCurrentOrderSheet.bind(this);
    }

    componentDidMount() {
        this.getCartContent();
    }

    getCartContent = () => {
        let content = 0;
        try {
            const cart = JSON.parse(localStorage.getItem("currentOrder"));
            content = cart.length;
        } catch (e) {
            console.log("Failed getting cart");
        }
        this.setState({cartItems: content})
    }

    getCurrentOrderSheet = () => {
        try {
            let order = JSON.parse(localStorage.getItem("currentOrderSheet"));
            let lastUsage = new Date(Date.parse(localStorage.getItem("lastUsage")));
            const diff = Math.abs(new Date() - lastUsage);
            const minutes = Math.floor((diff / 1000) / 60);
            if (minutes > 15) {
                return DefaultOrder();
            }
            console.log("[FoundOrder]", order, lastUsage);
            if (!order) return DefaultOrder();
            return order;
        } catch (e) {
            console.log("Failed getting order");
            return DefaultOrder();
        }
    }

    resetEdit = () => {
        try {
            if (localStorage.getItem("currentOrder") == null || JSON.parse(localStorage.getItem("currentOrder")).length == 0) {
                localStorage.removeItem("isEdit");
                localStorage.removeItem("editName");
                localStorage.removeItem("editId");
            }
        } catch (e) {
        }
    }

    render() {
        this.resetEdit()
        return (
            <Layout cartItems={this.state.cartItems}>
                <Routes>
                    <Route exact path='/' element={<Home/>}/>
                    <Route exact path='/reservation' element={<ReservationPage/>}/>
                    <Route path='/order' element={<OrderPage onSubmit={this.getCartContent}
                                                                     editOrder={this.getCurrentOrderSheet()}/>}/>
                    <Route path='/cart' element={<Cart onSubmit={this.getCartContent}/>}/>
                    <Route path='/reset' element={<ResetPage/>}/>
                    <Route path='/privacy' element={<Privacy/>}/>
                    <Route path='/impressum' element={<Impressum/>}/>
                </Routes>
            </Layout>
        );
    }
}
