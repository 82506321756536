import React, {Component} from 'react';
import {Table} from "reactstrap";

export class Home extends Component {
    static displayName = Home.name;

    state = {
        hours: {
            restaurant: {
                1: {openHours: []},
                2: {openHours: []},
                3: {openHours: [{fromString: "17:00", toString: "24:00"}]},
                4: {openHours: [{fromString: "17:00", toString: "24:00"}]},
                5: {openHours: [{fromString: "17:00", toString: "24:00"}]},
                6: {openHours: [{fromString: "17:00", toString: "24:00"}]},
                7: {openHours: [{fromString: "17:00", toString: "24:00"}]},
            },
            kitchen: {
                1: {openHours: []},
                2: {openHours: []},
                3: {openHours: [{fromString: "17:00", toString: "22:30"}]},
                4: {openHours: [{fromString: "17:00", toString: "22:30"}]},
                5: {openHours: [{fromString: "17:00", toString: "22:30"}]},
                6: {openHours: [{fromString: "17:00", toString: "22:30"}]},
                7: {openHours: [{fromString: "17:00", toString: "22:30"}]},
            },
            delivery: {
                1: {openHours: []},
                2: {openHours: []},
                3: {openHours: []},
                4: {openHours: []},
                5: {openHours: []},
                6: {openHours: []},
                7: {openHours: []},
            }

        },
        regions: []
    }

    lang = "de";

    constructor(props) {
        super(props);
        this.lang = localStorage.getItem("lang");
    }

    componentDidMount() {
        async function fetchHours() {
            const response = await fetch("/hours/generalHours");
            return await response.json();
        }

        async function fetchRegions() {
            const response = await fetch(`/regions?lang=${localStorage.getItem("lang")}`);
            return await response.json();
        }

        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.defer = true;
        s.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
        s.crossOrigin = "anonymous"
        document.body.appendChild(s);
        fetchHours().then(res => this.setState({hours: res}));
        fetchRegions().then(res => this.setState({regions: res}));
    }

    text = {
        "title": ["The Irish Steyr", "The Irish Steyr"],
        "welcome": ["Willkommen im The Irish!", "Welcome to The Irish!"],
        "intro": ["Bestelle die besten Burger, Wings & Co in Steyr jetzt auch online ! :)", "To make it easier for you to order your favourite burgers and wings, we created this awesome online configurator :)"],
        "tutorial_heading": ["Wie funktioniert's?", "How does it work?"],
        "tutorial_step_1a": ["Bestellpositionen hinzufügen: ", "Add items to your order "],
        "tutorial_step_1b": [" Klicke auf den Menüeintrag ", "Click the menu button "],
        "tutorial_step_1c": ["Bestellen", "Order"],
        "tutorial_step_1d": [", um eine Bestelleinheit zu erfassen - analog zu unseren Bestell-Formularen.", " to do so - just like with the printed sheets in our restaurant :)"],
        "tutorial_step_2a": ["Bestellung abschließen: ", "Finish order: "],
        "tutorial_step_2b": [" Auf der Seite ", "You can submit you order by navigating to the "],
        "tutorial_step_2c": ["Warenkorb", "Cart"],
        "tutorial_step_2d": [" kann die Bestellung geprüft und abgeschickt werden.", " page."],
        "tutorial_step_3a": ["Warte auf Bestätigung: ", "Wait for confirmation: "],
        "tutorial_step_3b": [" Wir bestätigen deine Bestellung manuell. Habe daher etwas Geduld, bis wir deine Bestellung per Mail bestätigen. Falls einmal etwas nicht möglich sein sollte oder wir deinen Abholtermin verschieben mussten, melden wir uns ebenfalls.",
            "We processes your order by hand. You will receive a second Email as soon as we have confirmed your order, or if we had to change your selected time slot."],
        "delivery_heading": ["Lieferung", "Delivery"],
        "delivery_info_1": ["Ab 15€ Bestellsumme bieten wir wieder einen Lieferservice für Steyr und Umgebung an. Wir möchten die Lieferungen möglichst effizient und umweltfreundlich gestalten - es werden deshalb erst nach Auswahl der Lieferregion verfügbare Zeiten angezeigt. ",
            "We currently once again offer a home delivery service (min. 15€ order total) in Steyr. We want to keep our delivery service as efficient and environmentally friendly as possible. Therefore, the available delivery times will only be shown after selecting a delivery region."],
        "delivery_info_2": ["Folgende Regionen werden beliefert:", "We deliver to the following regions:"],
        "delivery_info_end": ["Sollte sich deine Adresse in keiner der angeführten Region befinden, ruf uns bitte an. Wir werden versuchen, eine Lösung zu finden.", "In case your address is not in any of the listed regions, please give us a call. We will try to find a solution."],
        "kitchen_hours": ["Öffnungszeiten", "Open Hours"],
        "kitchen_hours_info": ["Neue Bestellungen können bis spätestens 20 Minuten vor Ende des Bestellzeitraums aufgegeben werden.", "New orders can be placed up until 20 minutes to the end of the hours listed below."],
        "kitchen_corona": ["Coronabedingt sind derzeit nur Abholung und Lieferung möglich!", "Due to Covid-19, only self-pickup and delivery are available!"],
        "kitchen": ["Küche", "Kitchen"],
        "collect": ["Abholung", "Self-Pickup"],
        "delivery": ["Lieferung", "Delivery"],
        "day": ["Tag", "Day"],
        "monday": ["Montag", "Monday"],
        "tuesday": ["Dienstag", "Tuesday"],
        "wednesday": ["Mittwoch", "Wednesday"],
        "thursday": ["Donnerstag", "Thursday"],
        "friday": ["Freitag", "Friday"],
        "saturday": ["Samstag", "Saturday"],
        "sunday": ["Sonntag", "Sunday"],
        "greeting_1": ["Wir freuen uns auf euch :)", "We are looking forward to meeting you :)"],
        "greeting_2": ["Das Team vom THE IRISH", "The IRISH Team"],
        "closed": ["geschlossen", "closed"],
        "contact": ["Kontakt", "Contact"],
        "phone": ["Telefon", "Phone"],
        "holidays": ["Wir sind bis 07.01.2021 auf Betriebsurlaub! Frohe Weihnachten und guten Rutsch :)", "We are taking a break and will be back for you after January 7, 2021! Merry Christmas and see you soon :)"]
    }

    deliveryEnabled = () => {
        return this.state.regions.length > 0
    }

    getText = key => {
        const index = this.lang === "en" ? 1 : 0;
        return this.text[key][index];
    }

    getDayText = weekDay => {
        switch (weekDay) {
            case 1:
                return this.getText("monday");
            case 2:
                return this.getText("tuesday");
            case 3:
                return this.getText("wednesday");
            case 4:
                return this.getText("thursday");
            case 5:
                return this.getText("friday");
            case 6:
                return this.getText("saturday");
            case 7:
                return this.getText("sunday");
            default:
                return "";
        }
    }

    render() {
        return (
            <div>
                <h1>{this.getText("title")}</h1>
                <div className={"centered-image"}><img src="/burger.jpg"/></div>
                <h3>{this.getText("welcome")}</h3>
                <p>{this.getText("intro")}</p>
                <br/>
                <h5>{this.getText("tutorial_heading")}</h5>
                <ul>
                    <li>
                        <strong>{this.getText("tutorial_step_1a")}</strong>{this.getText("tutorial_step_1b")}<span
                        className={"text-irish-highlight"}>{this.getText("tutorial_step_1c")}</span>{this.getText("tutorial_step_1d")}
                    </li>
                    <br/>
                    <li>
                        <strong>{this.getText("tutorial_step_2a")}</strong>{this.getText("tutorial_step_2b")}<span
                        className={"text-irish-highlight"}>{this.getText("tutorial_step_2c")}</span>{this.getText("tutorial_step_2d")}
                    </li>
                    <br/>
                    <li><strong>{this.getText("tutorial_step_3a")}</strong>{this.getText("tutorial_step_3b")}
                    </li>
                </ul>
                <br/><br/>
                {this.deliveryEnabled() && <>
                    <h5>{this.getText("delivery_heading")}</h5>
                    <p>{this.getText("delivery_info_1")}</p>
                    <strong>{this.getText("delivery_info_2")}</strong>
                    <ul style={{"columns": 2, "listStyle": "circle"}}>
                        {this.state.regions.map(r => <li>{r.name}</li>)}
                    </ul>
                    <br/>
                    <p>{this.getText("delivery_info_end")}</p>
                    <br/><br/>
                </>
                }

                <h5>{this.getText("kitchen_hours")}</h5>
                {this.getText("kitchen_hours_info")}<br/>
                <br/>
                <Table dark>
                    <thead>
                    <tr>
                        <th>
                            {this.getText("day")}
                        </th>
                        <th>
                            Pub
                        </th>
                        <th>
                            {this.getText("collect")}
                        </th>
                        {this.deliveryEnabled() &&
                        <th>
                            {this.getText("delivery")}
                        </th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {[1, 2, 3, 4, 5, 6, 7].map(x =>
                        <tr>
                            <td>
                                {this.getDayText(x)}
                            </td>
                            <td>
                                {this.state.hours.restaurant[x].openHours.length > 0 ? this.state.hours.restaurant[x].openHours.map(h =>
                                    <div>{h.fromString}-{h.toString}</div>) : this.getText("closed")}
                            </td>
                            <td>
                                {this.state.hours.kitchen[x].openHours.length > 0 ? this.state.hours.kitchen[x].openHours.map(h =>
                                    <div>{h.fromString}-{h.toString}</div>) : this.getText("closed")}
                            </td>
                            {this.deliveryEnabled() &&
                            <td>
                                {this.state.hours.delivery[x].openHours.length > 0 ? this.state.hours.delivery[x].openHours.map(h =>
                                    <div>{h.fromString}-{h.toString}</div>) : this.getText("closed")}
                            </td>
                            }
                        </tr>
                    )}
                    </tbody>
                </Table>
                <br/>
                <p>{this.getText("greeting_1")}</p>
                <p>{this.getText("greeting_2")}</p>
                <br/>
                <br/>
                <h5>{this.getText("contact")}</h5>
                <div>Email: dino@theirish.eu</div>
                <div>{this.getText("phone")}: <a className={"white"} href="tel:+43 7252 7119612">+43 7252 7119612</a>
                </div>
                <br/>
                <h5>Location & Social</h5>
                <div>The Irish</div>
                <div>Gleinker Gasse 14</div>
                <div>4400 Steyr, Austria</div>
                <br/>
                <div className="social-button">
                    <a href="http://www.facebook.com/TheIrishSteyr">
                        <img src="irish_fb.png" width="50" height="50"/>
                    </a>
                </div>
                <div className="social-button">
                    <a href="https://www.instagram.com/explore/locations/1416409758372836/the-irish/">
                        <img src="irish_ig.png" width="50" height="50"/>
                    </a>
                </div>
                <br/>
                <br/>
                <div id="fb-root"></div>
                <div className="fb-like" data-href="http://www.facebook.com/TheIrishSteyr" data-send="false"
                     data-width="310" data-show-faces="true" data-colorscheme="dark" data-layout="standard"
                     data-action="like"></div>
                <br/>
                <br/>

                <iframe className="actAsDiv" style={{width: "100%", height: "300px"}} frameBorder="0" scrolling="no"
                        marginHeight="0" marginWidth="0"
                        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;q=The%20Irish%2C%20Gleinker%20G.%2014%2C%204400%20Steyr%2C%20Austria&amp;aq=0&amp;ie=UTF8&amp;t=m&amp;z=15&amp;iwloc=A&amp;output=embed"></iframe>


            </div>
        );
    }

}
