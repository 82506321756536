import React from "react";

const ConfirmButton = props => {
    return (
        <button className={"baseBtn confirmBtn bigger" + (props.bordered && " bordered")} disabled={props.disabled}
                onClick={props.onClick}>
            <div className={"confirmBtn-text"}>{props.disabled ? props.disabledText : props.activeText}</div>
            {!props.disabled && <div className={"confirmIcon"}/>}
        </button>
    );

}
ConfirmButton.defaultProps = {
    disabled: false,
    disabledText: "Noch nicht bereit...",
    activeText: "Bestätigen."
}
export default ConfirmButton;