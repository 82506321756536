import React, {Component} from "react";
import {Input, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, FormFeedback, Button} from "reactstrap";
import ConfirmButton from "./../OrderForm/ConfirmButton";
import {GermanDatePicker as DatePicker} from "./../DatePicker/GermanDatePicker";
import OrderDateTimePicker from "./OrderDateTimePicker";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export default class FinishModal extends Component {
    state = {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        region: 0,
        address: "",
        zip: "",
        city: "",
        deliverMethod: "collect",
        date: null,
        emailValid: true,
        privacy_accepted: false,
        props_times: [],
        regions: [],
        openToDate: new Date(),
        showConfirm: false,
        step: 1 // Finish Process Step (1 = Abholung / Lieferung wählen, 2 = Region wählen (falls Lieferung),  3 = Daten eingeben)
    }

    constructor(props) {
        super(props);
        this.validateEmail = this.validateEmail.bind(this);
        this.isEmailValid = this.isEmailValid.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.showConfirmPrompt = this.showConfirmPrompt.bind(this);
    }
    
    componentDidMount() {
        fetch("/regions?lang=" + this.props.lang)
            .then(res => res.json())
            .then(json => this.setState({regions: json}))
    }

    complete = () => {
        const s = this.state;
        if (this.state.deliverMethod === "collect")
            return s.firstname.length > 0 && s.lastname.length > 0 && this.isEmailValid() && s.phone && s.phone.length > 9 && this.dateIsAvailable(this.state.date) && s.privacy_accepted;
        return s.firstname.length > 0 && s.lastname.length > 0 && this.isEmailValid() && s.phone && s.phone.length > 9 && this.dateIsAvailable(this.state.date) && s.address.length > 3 && s.zip.length === 4 && s.city.length > 0 && s.privacy_accepted;
    }

    renderDeliverInputs = () => {
        return (
            <div className={"deliverInput-container"}>
                <FormGroup>
                    <Input value={this.state.address}
                           onChange={e => this.setState({address: e.target.value})}
                           onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                           type="text" placeholder={this.props.lang === "en" ? "Address" : "Straße und Hausnummer"}/>
                </FormGroup>
                <FormGroup>
                    <Input value={this.state.zip} onChange={e => this.setState({zip: e.target.value})}
                           onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                           type="text" placeholder={this.props.lang === "en" ? "Zip" : "PLZ"}/>
                </FormGroup>
                <FormGroup>
                    <Input value={this.state.city}
                           onChange={e => this.setState({city: e.target.value})}
                           onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                           type="text" placeholder={this.props.lang === "en" ? "City" : "Ort"}/>
                </FormGroup>
            </div>
        );
    }

    dateIsAvailable(date) {
        if (date == null) return false;
        const date_processed = new Date();
        date_processed.setHours(date.getHours(), date.getMinutes(), 0);

        let dates = [];
        this.state.props_times.forEach(t => {
            if (new Date(t.validDay).toDateString() === date.toDateString())
                dates.push(t);
        });
        let ret = false;
        dates.forEach(d => {
            d.openHours.forEach(o => {
                const from = new Date();
                const to = new Date();
                from.setHours(o.from.hour, o.from.minute, 0);
                to.setHours(o.to.hour, o.to.minute, 0);
                if (+from <= +date_processed && +to >= +date_processed) {
                    ret = true;
                }
            })
        })
        return ret;
    }

    isEmailValid() {
        const {email} = this.state;
        return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    }

    validateEmail() {
        this.setState({emailValid: this.isEmailValid()});
    }

    showConfirmPrompt() {
        this.setState({showConfirm: true});
    }

    onConfirm() {
        this.props.onConfirm({
            date: this.state.date,
            comment: this.state.comment,
            deliverType: this.state.deliverMethod,
            region: this.state.region,
            customer: {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                email: this.state.email,
                phone: this.state.phone,
                address: this.state.address,
                zip: this.state.zip,
                city: this.state.city
            }
        })
    }

    text = {
        "monday": ["Montag", "Monday"],
        "tuesday": ["Dienstag", "Tuesday"],
        "wednesday": ["Mittwoch", "Wednesday"],
        "thursday": ["Donnerstag", "Thursday"],
        "friday": ["Freitag", "Friday"],
        "saturday": ["Samstag", "Saturday"],
        "sunday": ["Sonntag", "Sunday"]
    }

    getText = key => {
        const index = this.props.lang === "en" ? 1 : 0;
        return this.text[key][index];
    }

    getDayText = weekDay => {
        switch (weekDay) {
            case 1:
                return this.getText("monday");
            case 2:
                return this.getText("tuesday");
            case 3:
                return this.getText("wednesday");
            case 4:
                return this.getText("thursday");
            case 5:
                return this.getText("friday");
            case 6:
                return this.getText("saturday");
            case 7:
                return this.getText("sunday");
            default:
                return "";
        }
    }

    render() {
        const today = new Date()
        /*
        let tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1);
        let minDate = new Date(Math.min.apply(null,this.getAvailableDates()));
        if (tomorrow < minDate)
            tomorrow = minDate;
         */
        if (this.state.showConfirm) {
            // getMonth returns values starting with 0! => January = 0, October = 9 => getMonth() + 1
            const dateString = `${this.getDayText(this.state.date.getDay())}, ${this.state.date.getDate()}.${this.state.date.getMonth() + 1}.${this.state.date.getFullYear()}`
            const timeString = `${this.state.date.toTimeString().substr(0,5)}`
            return (
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                    <ModalHeader>{this.props.lang === "en" ? "Confirmation Required!" : "Bitte bestätigen Sie die Bestellung!"}</ModalHeader>
                    <ModalBody>
                        <div>{this.props.lang === "en" ? "Order Date: " : "Bestelldatum: "}<strong>{dateString}</strong>
                        </div>
                        <div>{this.props.lang === "en" ? "Order Time: " : "Bestellzeitpunkt: "}<strong>{timeString}</strong>
                        </div>
                        <div>{this.props.lang === "en" ? "Order Total: " : "Summe: "}<strong>{this.props.price} €</strong> ({this.props.lang === "en" ? "cash payments only" : "nur Barzahlung"})
                        </div>
                        <br/>
                        <div>{this.props.lang === "en" ? "Please note that Date and Time can not be changed after placing the order! " : "Datum und Zeitpunkt sind fix und können nachträglich nicht mehr geändert werden! "}</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.setState({showConfirm: false})} className={"mr-auto"}>zurück</Button>
                        <ConfirmButton
                            onClick={this.onConfirm}>
                            {this.props.lang === "en" ? "Place Request" : "Verbindlich anfragen"}</ConfirmButton>
                    </ModalFooter>
                </Modal>
            )
        }
        
        switch (this.state.step) {
            case 1:
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>{this.props.lang === "en" ? "Step 1" : "Abschluss - Schritt 1"}</ModalHeader>
                        <ModalBody className="long-modal-body-scrollable">
                            <FormGroup tag="fieldset" className={"deliverForm-radio"}>
                                <h5>{this.props.lang === "en" ? "Deliver/Collect" : "Zustellart"}</h5>
                                <div className={"radioWrapper"}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="collect"
                                                   checked={this.state.deliverMethod === "collect"}
                                                   onClick={() => this.setState({deliverMethod: "collect", region: 0})}/>{' '}
                                            {this.props.lang === "en" ? "Collect" : "Abholung"}
                                        </Label>
                                    </FormGroup>
                                    {this.props.enableDeliver &&
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="deliver"
                                                   checked={this.state.deliverMethod === "deliver"}
                                                   disabled={this.props.price<15}
                                                   onClick={() => this.setState({deliverMethod: "deliver"})}/>{' '}
                                            {this.props.price < 15 ?
                                                this.props.lang === "en" ? "Delivery (minimum order total: 15€)" : "Lieferung (ab 15€ Mindestbestellbetrag)" :
                                                this.props.lang === "en" ? "Delivery" : "Lieferung"
                                            }
                                        </Label>
                                    </FormGroup>
                                    }</div>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success"
                                    onClick={() => this.setState({step: this.state.deliverMethod == "collect" ? 3 : 2})}>{this.props.lang === "en" ? "Next Step" : "Weiter"}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            case 2:
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>{this.props.lang === "en" ? "Step 2" : "Abschluss - Schritt 2"}</ModalHeader>
                        <ModalBody className="long-modal-body-scrollable">
                            <FormGroup tag="fieldset" className={"deliverForm-radio"}>
                                <h5>{this.props.lang === "en" ? "Delivery Region" : "Lieferregion"}</h5>
                                <p>{this.props.lang === "en" ? "In order to optimize our delivery routes, we try to group orders by region. Please help us by selecting the region closest to your delivery address!":
                                "Um die Lieferrouten möglichst effizient zu gestalten, wähle bitte die Region aus, in der sich deine gewünschte Lieferadresse befindet. Sollte dein Gebiet nicht aufgeführt sein, informieren wir telefonisch gerne über unsere Liefergebiete!"}</p>
                                <div className={"radioWrapper"}>
                                    {this.state.regions.map(r => 
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name={"region_" + r.id}
                                                       checked={this.state.region === r.id}
                                                       onClick={() => this.setState({region: r.id})}/>
                                                {r.name}
                                            </Label>
                                        </FormGroup>
                                    )}
                                </div>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success"
                                    onClick={() => this.setState({step: 1})}>{this.props.lang === "en" ? "Back" : "Zurück"}
                            </Button>                            
                            <Button color="success"
                                    onClick={() => this.setState({step: 3})}
                                    disabled={this.state.region === 0}>{this.state.region === 0 ? (this.props.lang === "en" ? "Choose Region..." : "Region wählen...") : (this.props.lang === "en" ? "Next Step" : "Weiter")}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
            case 3:
                return (
                    <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                        <ModalHeader>{this.props.lang === "en" ? "We need some more info!" : "Einige Infos brauchen wir noch!"}</ModalHeader>
                        <ModalBody className="long-modal-body-scrollable">
                            <div>
                                <FormGroup tag="fieldset">
                                    <h5>{this.props.lang === "en" ? "Time" : "Zeitpunkt"}</h5>
                                    <FormGroup>
                                        <OrderDateTimePicker
                                            onChange={(date) =>
                                                this.setState({date: date})
                                            }
                                            onAvailableTimesChanged={(times) => this.setState({props_times: times})}
                                            isValid={this.dateIsAvailable(this.state.date)}
                                            lang={this.props.lang}
                                            region={this.state.region}
                                            date={this.state.date}
                                            burgers={this.props.burgers}
                                            wings={this.props.wings}
                                        />
                                    </FormGroup>
                                </FormGroup>
                            </div>

                            <h5>{this.props.lang === "en" ? "Conctact Information" : "Kontaktinfos"}</h5>
                            <FormGroup>
                                <Input value={this.state.firstname}
                                       onChange={e => this.setState({firstname: e.target.value})}
                                       onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                                       type="text" placeholder={this.props.lang === "en" ? "First Name" : "Vorname"}/>
                            </FormGroup>
                            <FormGroup>
                                <Input value={this.state.lastname}
                                       onChange={e => this.setState({lastname: e.target.value})}
                                       onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                                       type="text" placeholder={this.props.lang === "en" ? "Last Name" : "Nachname"}/>
                            </FormGroup>
                            <FormGroup>
                                <Input value={this.state.email}
                                       onChange={e => this.setState({email: e.target.value})}
                                       onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                                       type="email" placeholder="Email"
                                       invalid={!this.state.emailValid}
                                       onBlur={() => this.validateEmail()}
                                />
                                <FormFeedback>Bitte eine gültige Mailadresse angeben.</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <PhoneInput
                                    className="form-control PhoneInput"
                                    placeholder={this.props.lang === "en" ? "Mobile number (in case of questions)" : "Telefon (für Rückfragen)"}
                                    value={this.state.phone}
                                    onChange={(e) => this.setState({phone: e})}
                                    defaultCountry="AT"
                                    withCountryCallingCode={true}
                                />
                                <FormFeedback>Bitte eine gültige Mailadresse angeben.</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input value={this.state.comment}
                                       onChange={e => this.setState({comment: e.target.value})}
                                       onKeyDown={e => e.key === 'Enter' ? this.props.onConfirm(this.state.value) : null}
                                       type="textarea"
                                       rows={5}
                                       placeholder={this.props.lang === "en" ? "Comments" : "Anmerkungen"}/>
                            </FormGroup>
                            {this.state.deliverMethod == "deliver" && this.renderDeliverInputs()}
                            <FormGroup className="pl-4">
                                <Input id="privacy" value={this.state.privacy_accepted}
                                       onChange={e => {
                                           this.setState({privacy_accepted: e.target.checked})
                                       }}
                                       type="checkbox"/>
                                <Label for="privacy">{this.props.lang === "en" ?
                                    <span>I accept the <a href="/privacy" target="_blank">privacy statement</a></span> :
                                    <span>Ich akzeptiere die <a href="/privacy"
                                                                target="_blank">Datenschutzerklärung</a></span>}</Label>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="success"
                                    onClick={() => this.setState({step: this.state.deliverMethod === "collect" ? 1 : 2})}>{this.props.lang === "en" ? "Back" : "Zurück"}
                            </Button>
                            <Button disabled={!this.complete()}
                                    color="success"
                                    onClick={this.showConfirmPrompt}>{this.props.lang === "en" ? "Confirm" : "Bestätigen"}
                            </Button>
                        </ModalFooter>
                    </Modal>
                )
        }
        
    }
}

FinishModal.defaultProps = {
    timeInterval: 10
}