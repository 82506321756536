import React, { Component } from "react";
import {Input, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import ConfirmButton from "./ConfirmButton";
class ConfirmModal extends Component {
    state = {
        value: "",
        step: 1
    }
    
    confirmAndRedirect = () => {
        this.props.onConfirm(this.state.value);
        this.props.history.push("cart");
    }
    
    render () {
        if (this.state.step === 1) {
            return (
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                    <ModalHeader><h3>{this.props.lang === "en" ? "Please enter your name" : "Für wen ist diese Bestellung?"}</h3></ModalHeader>
                    <ModalBody><Input autoFocus={true} value={this.state.value}
                                      onChange={e => this.setState({value: e.target.value})}
                                      onKeyDown={e => e.key === 'Enter' ? this.setState({step:2}) : null}
                                      type="text"/></ModalBody>
                    <ModalFooter>
                        <ConfirmButton disabled={this.state.value.length < 1} activeText={"Bestätigen"}
                                       disabledText={this.props.lang === "en" ? "Please enter something :)" : "Bitte gib etwas ein :)"}
                                       onClick={() => this.setState({step:2})}
                                       bordered
                        />
                    </ModalFooter>
                </Modal>
            );
        }
        else {
            return (
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} autoFocus={false} className="dark">
                    <ModalHeader><h3>{this.props.lang === "en" ? "Next Step...?" : "Wie soll's weitergehen?"}</h3></ModalHeader>
                    <ModalBody>
                        <button disabled={this.state.value.length < 1} className={"baseBtn modalBtn bigger rightAuto bordered"}
                                onClick={() => this.props.onConfirm(this.state.value)}><div>{this.props.lang === "en" ? "Add another order sheet" : "Weitere Bestellung aufnehmen"}</div></button>
                        <ConfirmButton disabled={this.state.value.length < 1} activeText={this.props.lang === "en" ? "Go to Cart" : "Abschließen"}
                                       onClick={() => this.confirmAndRedirect()}
                        bordered/>
                    </ModalBody>
                </Modal>
            );
        }
        
    }
}

export default ConfirmModal;