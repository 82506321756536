import React, {Component} from 'react';
import {Table} from "reactstrap";

export class ResetPage extends Component {

    render() {
        localStorage.removeItem("currentOrderSheet");
        localStorage.removeItem("currentOrder");
        return (
            <div>
                <h1>Session wurde zurückgesetzt.</h1>
            </div>
        );
    }
}
